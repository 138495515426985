body,
button,
div,
span,
.ant-btn,
li,
ul {
  margin: 0;
  font-family: "Be Vietnam Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout {
  padding: 15px;
}

.ant-layout-header {
  position: sticky;
  top: 0px;
  z-index: 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 0px;
  line-height: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-dropdown ul {
  padding: 0px !important;
}
.pick-dropdown {
  margin-top: -73px !important;
  padding: 0px !important;
}
.header-dropdown ul li {
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center !important;
  color: #003c3c !important;
  padding: 8px 15px !important;
  background: #ffffff;
}
.header-dropdown ul li:last-child:hover a.sign-out {
  color: #003c3c;
}
.header-dropdown ul li a.sign-out {
  color: #ffffff;
}

.header-dropdown ul li:last-child {
  background: #f21167 !important;
  color: #fff !important;
}

.header-dropdown ul li:last-child:hover {
  background: #e0fbee !important;
  color: #003c3c !important;
}

.pick-dropdown .ant-select-item {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #444444 !important;
  padding: 10px 25px !important;
}

.ant-modal-mask {
  background: rgba(255, 255, 255, 0.88);
  backdrop-filter: blur(2px);
}

.operator .ant-modal-content {
  border: 4px solid #4d9a9a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 10px;
}

.operator .ant-modal {
  width: 350px !important;
}

.operator .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.operator .ant-modal-footer button {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  height: 60px;
  border-radius: 12px;
  min-width: 150px;
}

.operator .ant-modal-footer button.ant-btn-default {
  background: #ff8b3d;
  border: 4px solid #ff643d;
}

.operator .ant-modal-footer button.ant-btn-primary {
  background: #07a568;
  border: 4px solid #4d9a9a;
}
.operator .ant-modal-footer button.ant-btn-default:hover {
  color: #ffffff;
}

.order .ant-message-notice-content {
  background: #1f5454 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
  padding: 10px 15px !important;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.error .ant-message-notice-content {
  background: #cd001a !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
  padding: 10px 15px !important;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.error .ant-message-notice-content .ant-message-error {
  display: flex;
  align-items: center;
  gap: 10px;
}
.error .ant-message-notice-content .ant-message-error svg {
  height: 25px;
  width: 25px;
}
.error .ant-message-notice-content svg {
  display: flex;
}

.order .ant-message-notice-content svg {
  margin-right: 10px;
  height: 30px;
}

.shimmer {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(92deg, #00000059 30%, #0000007d, #00000014 70%)
    right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.common .ant-select-selection-item {
  font-size: 20px;
  color: #444444;
  font-weight: 700;
  padding-inline-start: 5px !important;
  margin: 0px 10px;
}
.common .ant-select-selection-item svg {
  padding-right: 5px;
}
.common .ant-select-selector {
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  color: rgb(68, 68, 68);
}

.common.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
}
.common .ant-select-arrow {
  inset-inline-start: 0px;
  font-size: 14px;
  color: #07a568;
}
.common .ant-select-arrow svg {
  height: 19px;
  width: 18px;
  backdrop-filter: blur(5px);
}

.common.arrow-right .ant-select-arrow {
  inset-inline-start: auto !important;
}
